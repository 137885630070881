import './index.scss'
import { useDispatch } from 'react-redux'
import { createSession } from '../../actions/userActions'

export default function SignIn({ history }) {
    const dispatch = useDispatch()
    const onSubmit = (e)=>{
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const formValues={ email: formData.get('email'), password_digest: formData.get('password_digest') }
        dispatch(createSession(formValues))
        history.push('/')
    }

    return (
        <div className="wrapper">
            <div className="container justify-content-center pt-4">
                <form onSubmit={onSubmit}>
                    <div className="d-flex justify-content-center ">
                        <h1>Login</h1>
                    </div>
                    <div className="d-flex m-3">
                        <input id='email' type='email' name='email' placeholder='Email' className="form-control email"/>
                    </div>
                    <div className="d-flex m-3">
                        <input id='password_digest' type='password' name='password_digest' placeholder='Password' className="form-control password" />
                    </div>
                    <input type='submit' value='Sign In'  className="form-control btn btn-secondary mt-2"/>
                </form>
            </div>
        </div>
    )
}
