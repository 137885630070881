import { useState, useEffect } from 'react'
import { orders, tracking } from '../../../actions/orderActions.js'
import { Link } from 'react-router-dom';

export default function ListAllOrders({ history }) {
    const [allOrders, setAllOrders] = useState([])

    const handleTracking = async ({item, e})=> {
        tracking({ item, track: e.target['tracking'].value }).then(data => {
            let thisItem = allOrders.find(item => item.orderID === data.item.orderID)
            thisItem['trackingID'] = data.track
            setAllOrders(prev => ([...prev]) )
        })
        e.currentTarget.reset()
    }

    const fetchOrders = async () => {
        let theseOrders = await orders()
        if (theseOrders && theseOrders.length) setAllOrders(theseOrders)
    }

    useEffect(()=>{
        fetchOrders()
    },[])

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>List All Orders</span>
            </div>
            <h5>List All Orders</h5>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>OrderID</th>
                        <th>Email</th>
                        <th>Tracking #</th>
                        <th>Add Tracking</th>
                    </tr>
                </thead>
                <tbody>
                {allOrders.length ? allOrders.map((item, i)=>
                    <tr key={i}>
                        <td><Link to={`/order?id=${item.orderID}&email=${item.profile.email}`}>{item.orderID}</Link></td>
                        <td>{item.profile.email}</td>
                        <td>{item.trackingID}</td>
                        <td>
                            <form onSubmit={ (e)=> { e.preventDefault(); handleTracking({item, e}) }} >
                                <input type='text' name='tracking' placeholder='Add tracking' required/>
                                <input type='submit' name='submit' />
                            </form>
                        </td>
                    </tr>
                ) : null}          
                </tbody>
            </table>
            <br></br>
            <button onClick={()=> history.push('/admin')}>Back</button>
        </div>
    )
}