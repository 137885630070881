import Resizer from 'react-image-file-resizer';
const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const APPROVE = 'APPROVE'
export const REVIEWS = 'REVIEWS';

export const reviews = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/review/reviews`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'GET'
        }).then(res => res.json())
        .then(data=> !data.error  && dispatch({ type: REVIEWS, data }))
    }
}
export const createReview = (body) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/review/review`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => res.json())
        .then(data => dispatch({ type: CREATE, data }))
    }
}
export const deleteReview = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/review/review`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'DELETE',
            body: JSON.stringify(params)
        }).then(res => res.json())
        .then(data => dispatch({ type: DELETE, data: params }) )
    }
}
export const approveReview = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/review/approve_review`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(params)
        }).then(res => res.json())
        .then(data => dispatch({ type: APPROVE, data: params }) )
    }
}
export const getSignedRequest = async (file) => {
    try {
        const reactResizer = (image) => {
            return new Promise(resolve => {
                Resizer.imageFileResizer(image, 300, 200, 'jpg', 100, 0, (uri) => resolve(uri), 'file')
            })
        }
        const resizedFile = await reactResizer(file);
        if (resizedFile.type.indexOf('image') !== -1 && resizedFile.size < 3e+6) {
            const { signedRequest, imgUrl } = await (await fetch(`${ baseUrl }/review/sign-s3?file-name=${resizedFile.name}&file-type=${resizedFile.type}`, {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include', 
                method: 'GET'
            })).json();
            await fetch(signedRequest, {
                headers: { 'Content-Type': resizedFile.type },
                method: 'PUT',
                body: resizedFile
            })
            return { success: imgUrl }
        }
        throw new Error()
    } catch (err) {
        return { error: 'Error uploading image'}
    }
}
