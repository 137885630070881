import { currentUser } from './userReducer'
import { cart } from './cartReducer'
import { payment } from './payReducer'
import { review } from './reviewReducer'
import { order } from './orderReducer'
import { shipping } from './shippingReducer'
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
    currentUser,
    cart,
    payment,
    review,
    order,
    shipping
})
