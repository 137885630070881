import './index.scss'
import { StarRating } from '../../utils/StarRating'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { deleteReview, approveReview } from '../../actions/reviewActions'

export default function Reviews() {
    const dispatch = useDispatch()
    const { user, allReviews } = useSelector(state => ({
        user: state.currentUser,
        allReviews: state.review.allReviews
    }));

    return (
        <div className='wrapper'>
            <div className='title'> reviews </div>
            <div className='reviews'>
                {allReviews.length ? allReviews?.map((review, i) =>
                    user?.role === 'admin' ? (
                        <div className="card" key={i}>
                            <div className="card-image">
                                <img src={review.image ? review.image : process.env.PUBLIC_URL + '/images/placeholder-image.png'} alt='Review'/>
                            </div>
                            <ul className="card-content">
                                <hr/>
                                <li>{ review?.fullname }</li>
                                <li><StarRating max={5} currentNumber={review.rating} /></li>
                                <li>{review.description}</li>
                                <hr/>
                                <li><Link to={`/products/${review.productname}`} >{review.productname}</Link></li>
                                <li>
                                    <button onClick={(e)=>{e.preventDefault(); dispatch(deleteReview(review))} }> 
                                        <small>DELETE</small> 
                                    </button>
                                    {!review.approved && (
                                        <button onClick={(e)=>{e.preventDefault(); dispatch(approveReview(review))} } > 
                                            <small>APPROVE</small>
                                        </button>
                                    )}
                                </li>
                            </ul>
                        </div> 
                    ) : review.approved ? (
                        <div className="card" key={i}>
                            <div className="card-image">
                                <img src={review.image ? review.image : process.env.PUBLIC_URL + '/images/placeholder-image.png'} alt='Review'/>
                            </div>
                            <ul className="card-content">
                                <hr/>
                                <li>{review.fullname}</li>
                                <li><StarRating max={5} currentNumber={review.rating} /></li>
                                <li>{review.description}</li>
                                <hr/>
                                <li><Link to={`/products/${review.productId}`} >{review.productname}</Link></li>
                            </ul>
                        </div> 
                    ) : null
                ) : null}
            </div>  
        </div>
    )
}
