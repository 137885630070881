import { ORDER } from '../actions/orderActions'

let initState = { order: {} } 

export const order = (state=initState, action)=>{
    if (action.type === ORDER){
        return { ...state, order: action.data }
    }
    else {
        return state
    }
}
