import './index.scss'

export default function Contact() {
    
    return (
        <div className='wrapper'>
            <div className='title'> contact us</div>
            <div className='contact'>
                
                <p>We are a small business located in Vancouver, BC.</p>
                <p>Email Us!</p>
                <p>kavaglambeauty@gmail.com</p>
            </div>
        </div>
    )
}
