import './StarRating.scss'

export function StarRating({ max, currentNumber, newWidth="12px" }) {
  let StarArray = []
  
  for (let i = 1; i <= max; i++) {
    if (i <= currentNumber) {
      	StarArray.push(
	  		<svg key={i} style={{ color: 'gold', width: newWidth }} viewBox='0 0 576 512' >
          		<path fill='gold' d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z' />
		 	</svg>
		)
	} else if (i > currentNumber && i-1 < currentNumber) {
		StarArray.push(
			<svg key={i} style={{ color: 'gold', width: newWidth }} viewBox='0 0 576 512' >
				<defs>
				<linearGradient id="orange_grey" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="50%" style={{ stopColor: "gold" }}></stop>
					<stop offset="50%" style={{ stopColor: "rgb(228, 229, 233)" }}></stop>
				</linearGradient>
				</defs>
				<path fill='url(#orange_grey)' d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z' />
		  	</svg>
		)
	} else {
      	StarArray.push(
			<svg key={i} width={newWidth} viewBox='0 0 576 512' >
				<path fill='#ddd' d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z' />
			</svg>
	  	)
    }
  }

  return (
  		<> {StarArray} </>
  	)
}

export function StarRatingSelect() {

    return (
		<fieldset className="rating" >
			<input type="radio" id="star5" name="rating" value="5" required/>
			<label className="full" htmlFor="star5" title="5 stars" />
			<input type="radio" id="star4half" name="rating" value="4.5" required/>
			<label className="half" htmlFor="star4half" title="4.5 stars" />
			<input type="radio" id="star4" name="rating" value="4" required/>
			<label className="full" htmlFor="star4" title="4 stars" />
			<input type="radio" id="star3half" name="rating" value="3.5" required/>
			<label className="half" htmlFor="star3half" title="3.5 stars" />
			<input type="radio" id="star3" name="rating" value="3" required/>
			<label className = "full" htmlFor="star3" title="3 stars" />
			<input type="radio" id="star2half" name="rating" value="2.5" required/>
			<label className="half" htmlFor="star2half" title="2.5 stars" />
			<input type="radio" id="star2" name="rating" value="2" required/>
			<label className = "full" htmlFor="star2" title="2 stars" />
			<input type="radio" id="star1half" name="rating" value="1.5" required/>
			<label className="half" htmlFor="star1half" title="1.5 stars" />
			<input type="radio" id="star1" name="rating" value="1" required/>
			<label className="full" htmlFor="star1" title="1 star" />
		</fieldset>
    )
}