import './index.scss'
import { useSelector } from 'react-redux';

export default function Order() {
    
    const { order, items } = useSelector(state => ({
        order: state.order.order,
        items: state.cart.items
    }))
    

    return (
        <div className='wrapper'>
			 <div className='title'>
                <span>Order Confirmation</span>
            </div>
            <div className='success'>
                <p>Your Order: </p>
                <h5>{order?.orderId}</h5>
                {Object.entries(order?.products).map(([key, val], idx) => (
                    <div key={ idx }>
                    <span>{ items.find(i => i.productId === key).productname }</span>
                    <span> --- </span>
                    <span>{ val }</span>
                    </div>
                ))}
                <div>
                    <p>{ order?.addressString }</p>
                    <p>{ `${order?.firstname} ${order?.lastname}` }</p>
                    <p>{ order?.phone }</p>
                </div>
            </div>
        </div>
    )
}