import './index.scss'

export default function Delivery() {

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>shipping & delivery</span>
            </div>
            <div className='delivery'>
                <ul>
                    <p>
                        All items are shipped via Canada Post OR Chitchat. Your estimated standard shipping time is 7-14 business days from Vancouver, BC. 
                    </p>
                    <p>Note: order will not include paper invoices. Order confirmation and receipts will be emailed to you at the time of purchase. </p>
                    <p>
                        Kavaglam is not responsible for orders where incorrect shipping information was provided by the buyer. If you realize an error was made, please contact us immediately at kavaglambeauty@gmail.com and we will try to fix your order if it has not yet been shipped yet.
                    </p>
                </ul>
            </div>
        </div>
    )
}