import {Route, Redirect} from 'react-router-dom'

export default function SuccessAuth({isAuth, component: Component, ...restProps}) {

    return(
        <Route {...restProps}
            render={
                (routeProps)=>{
                    if (isAuth){
                        return <Component {...routeProps}/>
                    } else {
                        return <Redirect to='/' />
                    }
                }
            }
        />
    )
}