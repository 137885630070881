import { Route, Redirect } from 'react-router-dom'
import { myOrder } from '../actions/orderActions'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react';

export default function OrderRoute({ props, component: Component, ...restProps }) {
    const dispatch = useDispatch()
    const [findOrder, setFindOrder] = useState('')

    useEffect(()=>{
        const params = restProps.location.search.match(/\?id=(\w*)&email=(.*)$/);
        dispatch(myOrder({ 
            orderId: params[1], 
            email: params[2] 
        })).then(data=> {
            if (data.data.orderId) setFindOrder('found')
            else setFindOrder('error')
        })
    }, [dispatch, restProps.location.search])

    return(
        <Route {...restProps}
            render={
                (routeProps)=>{
                    if (findOrder === "found"){
                        return <Component {...routeProps}/>
                    } else if (findOrder === "error"){
                        return <Redirect to='/' />
                    }
                }
            }
        />
    )
} 