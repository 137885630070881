import { useState, useEffect } from 'react'
import { createPromo, listPromos, deletePromo, sitewidePromo } from '../../../actions/promoActions.js'

export default function ListAllPromos({ history }) {

    const [promoMessage, setPromoMessage] = useState('')
    const [promoList, setPromoList] = useState([])
    const handlePromoSubmit = async (e)=>{
        e.preventDefault();
        let submitPromo = await createPromo({
            name: e.target.name.value.toUpperCase(),
            percent: e.target.percent.value,
            expire: e.target.expire.value
        })
        submitPromo.success ? setPromoMessage('Created Promo Code') : setPromoMessage('Invalid Promo Info')
        await fetchPromos()
    }
    const handlePromoSitewide = async (promo)=> {
        if (promoList.find(item => item.sitewide) && promoList.find(item => item.sitewide) !== promo) {
            await sitewidePromo(promoList.find(item => item.sitewide))
        }
        sitewidePromo(promo).then(() => {
            fetchPromos()
        })
    }
    const handlePromoDelete = async (promo)=> {
        deletePromo(promo).then(data => {
            let newList = promoList.filter(p => p.promoID !== promo.promoID)
            if (newList) setPromoList(newList)
        })
    }
    const fetchPromos = async () => {
        let promos = await listPromos()
        if (promos && promos.length) setPromoList(promos)
        
    }
    useEffect(()=>{
        fetchPromos()
    },[])

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>List All Promos</span>
            </div>
            <h5>Create Promo Code</h5>
            <form onSubmit={handlePromoSubmit}>
                <input type='text' name='name' placeholder='Promo Code Name' required />
                <input type='number' name='percent' placeholder='Discount Percentage' min='0' max='100' required />
                <input type='date' name='expire' min={Date()} required />
                <input type='submit' value='Submit' className='btn btn-secondary btn-sm' />
            </form>
            {promoMessage && <p>{promoMessage}</p>}
            <br></br>
            <h5>List All Promo Codes</h5>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Promo Code Name</th>
                        <th>Percentage</th>
                        <th>Expires</th>
                        <th>Delete</th>
                        <th>Sitewide</th>
                    </tr>
                </thead>
                <tbody>
                {promoList.length ? promoList.map((item, i)=> 
                    <tr key={i}>
                        <td>{item.promoID}</td>
                        <td>{item.percent}</td>
                        <td>{new Date(item.expire).toString().slice(0,24)}</td>
                        <td>
                            <button onClick={(e)=>{e.preventDefault(); handlePromoDelete(item)} }> 
                                <small>DELETE</small> 
                            </button>
                        </td>
                        <td>
                            <input type="radio" name="sitewide" className="input" checked={ item?.sitewide ? true : false } readOnly
                            onClick={(e)=> {e.preventDefault(); handlePromoSitewide(item)}}
                            />
                        </td>
                    </tr>
                ) : null}          
                </tbody>
            </table>
            <br></br>
            <button onClick={()=> history.push('/admin')}>Back</button>
        </div>
    )
}