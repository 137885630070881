const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const MAILING_LIST = 'MAILING_LIST';

export const subscribe = (params)=>{
    return fetch(`${ baseUrl }/newsletter/subscribe`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify({ email: params })
    }).then(res=>res.json())
    .then(data=> data)
}
export const unsubscribe = (params)=>{
    return fetch(`${ baseUrl }/newsletter/unsubscribe`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify({ email: params })
    }).then(res=>res.json())
    .then(data=> data)
}
export const listNewsletter = ()=>{
    return fetch(`${ baseUrl }/newsletter/newsletter_list`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST'
    }).then(res=>res.json())
    .then(data=> data)
}


  