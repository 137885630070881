import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export default function useGaTracker() {
    let location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        
        if (!window.location.href.includes("localhost")) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
        ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

