
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './reducers/rootReducer';
import thunk from 'redux-thunk';
import App from './App';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { initialState } from './initialState'

const root = createRoot(document.getElementById('root'));
export const store = createStore(rootReducer, initialState, applyMiddleware(thunk))

const stripeKey = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_PUBLISHABLE_KEY_LIVE 
    : process.env.REACT_APP_PUBLISHABLE_KEY_TEST;

root.render(
    <Provider store={store}>
        <Elements stripe={
            loadStripe(stripeKey)
        }>
         <BrowserRouter><App /></BrowserRouter>
        </Elements>
    </Provider>
);


