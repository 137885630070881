import './index.scss'
import { resetState } from '../../actions/cartActions'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';

export default function Success() {
    const dispatch = useDispatch()
    const { orderId } = useSelector(state => ({
        orderId: state.cart.orderId
    }))

    useEffect(() => {
        (async () => {
            await dispatch(resetState());
        })()
    }, [dispatch])


    return (
        <div className='wrapper'>
			 <div className='title'>
                <span>Order Confirmation</span>
            </div>
            <div className='success'>
                <p>Thank you for your order. You will recieve an email with your order information shorty. </p>
                <p>Once the item have been shipped, you will recieve another email with your tracking information. </p>
                <p>For your reference, your order number is: </p>
                <span></span>
                <h5>{orderId}</h5>
            </div>
        </div>
    )
}