import { CREATE, DELETE, APPROVE, REVIEWS } from '../actions/reviewActions'

let initState = { allReviews: [] } 


export const review = (state=initState, action)=>{
    if (action.type === CREATE){
        return { ...state, allReviews: state.allReviews.concat(action.data) }
    } 
    if (action.type === APPROVE){
        state.allReviews.find(r => r.reviewId === action.data).approved = true
        return { ...state  }
    } 
    if (action.type === DELETE){
        return { ...state, allReviews: state.allReviews.filter(r => r.reviewId !== action.data)  }
    } 
    if (action.type === REVIEWS){
        return { ...state, allReviews: action.data }
    }
    else {
        return state
    }
}
