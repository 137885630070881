import { INITIAL_STATE, CART, ADD_SHIPPING, SUB_SHIPPING, ADD_MSG, ADD_PROMO, CREATE_ORDER, RESET_STATE } from '../actions/cartActions'

let initState = { addedItems: {}, items: [], promo: {}, total: 0, subtotal: 0,  discount: 0, shipping: 0, rate: {}, msg:'', orderId:'' } 

export const cart = (state = initState, action) => {
    if (action.type === INITIAL_STATE){
        return {  ...state, items: action.items }
    }
    if (action.type === CART){
        let newTotal = Object.entries(action.data).reduce((acc, [key, value]) => { 
            return acc + value * state.items.find(p => p.productId === key).price;
        }, 0) || 0;
        return {
            ...state, 
            addedItems: action.data, discount: newTotal * (state.promo.percent / 100), 
            subtotal: newTotal - (newTotal * (state.promo.percent / 100)), 
            total: newTotal
        };
    }
    
    if (action.type === ADD_SHIPPING){
        let rate = Number(action.data.priceDetails.due)
        if (state.discount){
            return { ...state, shipping: rate, rate: action.data, subtotal: (state.total - state.discount) + rate }
        } else {
            return  { ...state, shipping: rate, rate: action.data, subtotal: state.total + rate }
        }
    }
    if (action.type === SUB_SHIPPING){
        return { ...state, total: state.total - 0 }
    } 
    if (action.type === ADD_MSG){
        return { ...state, msg: action.data}
    }
    if (action.type === ADD_PROMO){
        if (action.data.error) {
            return { ...state, discount: 0, subtotal: state.total + state.shipping }
        } else {
            let discount = state.total * (action.data.percent/100.00)
            if (state.shipping) {
                return { ...state, promo: action.data,  discount: discount, subtotal: state.total - discount + state.shipping }
            } else {
                return { ...state, promo: action.data,  discount: discount, subtotal: state.total - discount }
            }
        }
    } 
    if (action.type === CREATE_ORDER){
        return { ...state, orderId: action.data.orderId }
    }
    if (action.type === RESET_STATE) {
        return { ...state, addedItems: {}, promo: {}, total: 0, subtotal: 0,  discount: 0, shipping: 0, rate: {}, msg:'' };
    }
    else {
        return state
    }
}
