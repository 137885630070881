const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const CREATE = 'CREATE';
export const PROMOS = 'PROMOS';

export const createPromo = (params) => {
    return fetch(`${ baseUrl }/promo/create`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify(params)
    }).then(res => res.json())
    .then(data => data)
}

export const listPromos = () => {
    return fetch(`${ baseUrl }/promo/promos`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST'
    }).then(res => res.json())
    .then(data => data.sort((a,b)=>b.expire - a.expire) )
}

export const deletePromo = (promo) => {
    return fetch(`${ baseUrl }/promo/promo`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(promo)
    }).then(res => res.json())
    .then(data => data)
}

export const sitewidePromo = (promo) => {
    return fetch(`${ baseUrl }/promo/sitewide`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify(promo)
    }).then(res => res.json())
    .then(data => data)
}
