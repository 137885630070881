const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const GET_RATES = 'GET_RATES';

export const getRates = ({ address, total}) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/shipping/rates`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify({ address })
        }).then(res => res.json())
        .then(data => dispatch({ type: GET_RATES, data, total }) )
    }
}