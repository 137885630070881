const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const CURRENT_USER = 'CURRENT_USER';
export const CREATE_SESSION = 'CREATE_SESSION';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_PROFILE = 'CREATE_PROFILE';

export const currentUser = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/auth/user`, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include', 
            method: 'GET'
        }).then(res => res.json())
        .then(data => !data.error && dispatch({ type: CURRENT_USER, data }))
    }
}
export const createSession = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/auth/session`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(params)
        }).then(res => res.json())
        .then(data => dispatch({ type: CREATE_SESSION, data }))
    }
}
export const destroySession = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/auth/session`, {
            credentials: 'include', 
            method: 'DELETE'
        }).then(res => res.json())
        .then(() => dispatch({ type: DESTROY_SESSION }))
    }
}
export const createUser = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/auth/user`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(params)
        }).then(res => res.json())
        .then(data => dispatch({ type: CREATE_USER, data }))
    }
}
export const createProfile = (data) => {
    return function(dispatch){
        // return fetch(`${BASE_URL}/auth/create_user`, {
        //     headers: { 'Content-Type':'application/json' },
        //     credentials: 'include', 
        //     method: 'POST',
        //     body: JSON.stringify(params)
        // }).then(res=>res.json())
        // .then(data=> dispatch({ type: CREATE_PROFILE, currentUser: data }))
        dispatch({ type: CREATE_PROFILE, data })
    }
}
