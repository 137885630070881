import './index.scss'
import { useDispatch } from 'react-redux'
import { createUser } from '../../actions/userActions'

export default function SignUp({ history }) {
    const dispatch = useDispatch()
    const onSubmit = (e)=>{
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const formValues={ email: formData.get('email'), firstname: formData.get('firstname'), lastname: formData.get('lastname'), password_digest: formData.get('password_digest') }
        dispatch(createUser(formValues))
        history.push('/')
    }

    return (
        <div className="wrapper">
            <div className="container justify-content-center pt-4">
                <form onSubmit={onSubmit}>
                    <div className="d-flex justify-content-center ">
                        <h1>Sign Up</h1>
                    </div>
                    <div className="d-flex m-3">
                        <input id='email' type='email' name='email' placeholder='Email' className="form-control email" autoCapitalize="off" spellCheck="false" minLength='4' maxLength='40' pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" required/>
                    </div>
                    <div className="d-flex m-3">
                        <input id='firstname' type='text' name='firstname' placeholder='First Name' className="form-control first_name"/>
                    </div>
                    <div className="d-flex m-3">
                        <input id='lastname' type='text' name='lastname' placeholder='Last Name' className="form-control last_name"/>
                    </div>
                    <div className="d-flex m-3">
                        <input id='password_digest' type='password' name='password_digest' placeholder='Password' className="form-control password"  autoCapitalize="off" />
                    </div>
                    <input type='submit' value='Sign Up'  className="form-control btn btn-secondary mt-2"/>
                </form>
            </div>
        </div>
    )
}
