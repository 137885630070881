const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const PAYMENT_INTENT = 'PAYMENT_INTENT';

export const paymentIntent = (params) => {
    return fetch(`${ baseUrl }/payment/payment_intent`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify(params)
    }).then(res => res.json())
    .then(data => data)
}

  