import './app.scss'
import { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { currentUser } from './actions/userActions'
import { currentCart, sitewide } from './actions/cartActions'
import { reviews } from './actions/reviewActions'
import useGaTracker from './utils/GoogleAnalytics'
import Admin from './components/Admin'
import ListAllPromos from './components/Admin/ListAllPromos'
import ListAllNewsletter from './components/Admin/ListAllNewsletter'
import ListAllOrders from './components/Admin/ListAllOrders'
import AdminAuth from './auth/AdminAuth'
import SuccessAuth from './auth/SuccessAuth';
import OrderAuth from './auth/OrderAuth';
import AuthRoute from './auth/AuthRoute';
import Subscribe from './components/Subscribe'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Products from './components/Products';
import ProductShow from './components/ProductShow';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Cart from './components/Cart'
import Info from './components/Info'
import Payment from './components/Payment'
import Success from './components/Success'
import Order from './components/Order'
import About from './components/About'
import Contact from './components/Contact'
import Faq from './components/Faq'
import Delivery from './components/Delivery'
import Returns from './components/Returns'
import Reviews from './components/Reviews'
import Shipping from './components/Shipping'

export default function App() {

	useGaTracker();

	const dispatch = useDispatch()
	const { user, addedItems, profile, shipping, orderId } = useSelector(state => ({
		user: state.currentUser,
    	addedItems: state.cart.addedItems,
		profile: state.currentUser.profile,
		shipping: state.cart.shipping,
		orderId: state.cart.orderId
    }))
	const [showModal, setShowModal] = useState(false);
    const handleModal = (e) => { e.preventDefault(); setShowModal(!showModal) };
  
	useEffect(()=>{
		(async () => {
			await dispatch(currentUser())
			await dispatch(currentCart())
			await dispatch(reviews())
			dispatch(sitewide())
		})()

		setTimeout(()=>{
			if (!document.cookie.includes('seen_modal')) {
				document.cookie = 'seen_modal=1';
				setShowModal(true);
			}
		}, 15e3)
	}, [dispatch])

  	return (
		<>
			<Navbar handleModal={handleModal}  />
			<Switch>
				<Route path='/about' render={(routeProps)=><About {...routeProps}/>} />
				<Route path='/contact' render={(routeProps)=><Contact {...routeProps}/>} />
				<Route path='/delivery' render={(routeProps)=><Delivery {...routeProps}/>} />
				<Route path='/returns' render={(routeProps)=><Returns {...routeProps}/>} />
				<Route path='/faq' render={(routeProps)=><Faq {...routeProps}/>} />
				<Route path='/reviews' render={(routeProps)=><Reviews {...routeProps}/>} />
				<AuthRoute path='/info' isAuth={addedItems} component={Info} />
				<AuthRoute path='/shipping' isAuth={addedItems && Object.keys(profile).length} component={Shipping} />
				<AuthRoute path='/payment' isAuth={addedItems && Object.keys(profile).length && shipping >= 0} component={Payment} />
				<SuccessAuth path='/success' isAuth={orderId} component={Success} />
				<AdminAuth path='/admin' exact isAuth={user} component={Admin} />
				<AdminAuth path='/admin/listallpromos' isAuth={ user } component={ListAllPromos} />
				<AdminAuth path='/admin/listallnewsletter' isAuth={user} component={ListAllNewsletter} />
				<AdminAuth path='/admin/listallorders' isAuth={user} component={ListAllOrders} />
				<OrderAuth path='/order' component={Order} />
				<Route path='/products/:id' render={(routeProps)=><ProductShow {...routeProps}/>} />
				<Route path='/products' render={(routeProps)=><Products {...routeProps}/>} />
				<Route path='/cart' render={(routeProps)=><Cart {...routeProps}/>} />
				<Route path='/sign_in' render={(routeProps)=><SignIn {...routeProps}/>} />
				<Route path='/sign_up' render={(routeProps)=><SignUp {...routeProps}/>} />
				<Route path='/' component={Home} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
			<Footer handleModal={handleModal} />
			<Subscribe showModal={showModal} handleModal={handleModal} />
		</>
	)
}