import './index.scss'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addItem } from '../../actions/cartActions'
import ReactImageMagnify from 'react-image-magnify';
import { StarRating, StarRatingSelect } from '../../utils/StarRating'
import { createReview, getSignedRequest } from '../../actions/reviewActions'

export default function ProductShow({ history, ...props}) {
    const dispatch = useDispatch()
    const { item, itemReviews, user } = useSelector(state => ({
        item: state.cart.items.find(p => p.productId === props.match.params.id),
        itemReviews: state.review.allReviews.filter(r => r.productId === props.match.params.id && r.approved) ,
        user: state.currentUser
    }))
    const [reviewSubmitted, setReviewSubmitted] = useState(false)
    const [writeReview, setWriteReview] = useState(false)
    const [magImg, setMagImg] = useState(item.images[0])
    const [imageUrl, setImageUrl] = useState('')
    const [reviewWordCount, setReviewWordCount] = useState(0)
    const [uploadMsg, setUploadMsg] = useState('');
    
    const handleReviewImage = (e)=> {
        (async () => {
            let result = await getSignedRequest(e.target.files[0]);
            if (result.error) setUploadMsg(result.error)
            if (result.success) {
                setImageUrl(result.success)
                setUploadMsg('Success')
            }
        })();
    }
    const handleReviewSubmit = (e)=> {
        e.preventDefault()
        const body = {
            "email": user.email || e.target['email']?.value,
            "productId": item.productId,
            "productname": item.productname,
            "description": e.target['description']?.value,
            "fullname": e.target['fullname']?.value,
            "rating": e.target['rating']?.value,
            "image": imageUrl
        }
        dispatch(createReview(body))
        setReviewSubmitted(!reviewSubmitted)
        setWriteReview(!writeReview)
        e.currentTarget.reset()
    }

    return (
        <div className="wrapper">
            <div className="title">
                <span>Beauty Brushes</span>
            </div>
            <div className="product-details">
                <div className="product-images">
                    <ReactImageMagnify {...{  largeImage: { src: magImg, width: 1200, height:1200, alt: item.productname },
                        smallImage: {  src: magImg , width: 350, height: 450, alt: item.productname }  }} />
                    <div className="product-image-thumb">
                        {item.images.map((img, key)=> ( 
                        <img src={img} key={key} alt={item.productname} onClick={()=> setMagImg(img)}/> 
                        ))}
                    </div>
                </div>
                <div className="product-info">
                    <h2>{item.productname}</h2>
                    <h5>${item.sale_price}</h5>
                    <p>Shipping calculated at checkout.</p>
                    <hr />
                    <button onClick={
                        async ()=> { 
                            await dispatch(addItem( item.productId ));
                            history.push('/cart') } 
                        }>ADD TO CART</button>
                    <p>{item.description}</p>
                    <ul>{item.set && item.set.map((s, key)=> (
                        <li key={key}>{s}</li>
                    ))}</ul>
                </div>
            </div>
            <div className="product-review-title">
                    <span><StarRating max={5} currentNumber={(Math.round(itemReviews.reduce((a, b, i)=>{return a+(b.rating-a)/(i+1)},0) * 2)/2).toFixed(1)} newWidth={"20px"} /></span>
                    <span>{itemReviews.length} Reviews</span>
                    <button onClick={()=>setWriteReview(!writeReview)}>{reviewSubmitted ? "Review Submitted" : "Write a Review" }</button>
                </div>
                <form onSubmit={handleReviewSubmit}className={writeReview ? "write-review" : "hide"}>
                    <textarea id="description" name="description" placeholder="Write your review" maxLength="400" onChange={e=>setReviewWordCount(e.target.value.length)} required/>
                    <label htmlFor="description">{reviewWordCount}/400</label>
                    <input type="text" name="fullname" placeholder="Your Name" minLength='2' required/>
                    <input type="email" name="email" placeholder="Your Email" minLength='2' required/>
                    <input type="file" onChange={handleReviewImage} />
                    { uploadMsg ? uploadMsg : null} 
                    <StarRatingSelect /> 
                    <input type="hidden" id="image-url" name="image"/>
                    <button type="submit" value="Submit Review">Submit Review</button>
                </form>
                <div className="product-review">
                    {itemReviews && itemReviews.map((review,key)=>
                        <div className="card" key={key}>
                            <div className="card-image">
                                <img src={review.image ? review.image : process.env.PUBLIC_URL + '/images/placeholder-image.png'} alt='Review'/>
                            </div>
                            <ul className="card-content">
                                <hr/>
                                <li>{review.fullname}</li>
                                <li><StarRating max={5} currentNumber={review.rating} /></li>
                                <li>{review.description}</li>
                                <hr/>
                                <li>{review.productname}</li>
                            </ul>
                        </div> 
                    )}
                </div>
        </div>
    )
}
