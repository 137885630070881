import './index.scss'
import { useState } from 'react'
import { subscribe } from '../../actions/newsletterActions'

export default function Subscribe({showModal, handleModal}) {
    const [newsletterMessage, setNewsletterMessage] = useState('');
    const handleNewsletterSubmit = async (e) => {
        e.preventDefault()
        let email = await subscribe(e.target.email.value)
        email && setNewsletterMessage(`thank you for joining`) 
    }
    if (showModal) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`
    } else {
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(document.body.style.top || '0') * -1);
    }
        
    return (
        <>
        <div className={showModal ? 'overlay' : 'hide'} onClick={handleModal} />
            <div className={showModal ? 'subscribe-modal' : 'hide'} >
            <a href='void(0)' onClick={handleModal}>
                <span className='icon-close'>x</span>
            </a>
            <form onSubmit={handleNewsletterSubmit}>
                <span>Join KAVAGLAM family!</span>
                <span>Subscribe and receive 10% off your first order!</span>
                {newsletterMessage && (<span>{newsletterMessage}</span>) }
                <input type='email' name='email' placeholder='Email'  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
                <input type='submit' value='Submit'/>
                <span>*By completing this form you are signing up to receive our emails and can unsubscribe at any time.</span>
            </form>
        </div>
        </>
    )
}
