import { PAYMENT_INTENT } from '../actions/payActions'

export const payment = (state={}, action)=>{
    if (action.type=== PAYMENT_INTENT){
        return { ...state, payment: action.payment}
    } 
    else {
        return state
    }
}
