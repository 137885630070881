import './index.scss'

export default function Faq() {

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>frequently asked questions</span>
            </div>
            <div className='faq'>
                <ul>
                    <strong>Whats the difference between the mini brushes and unicorn brushes?</strong>
                    <li>The mini brush lengths are approx four inches verus the regular brushes at 8 inchs</li>
                </ul>
                <ul>
                    <strong>What is the size difference between the minis and the regular size?</strong>
                    <li>The sizes of the minis are 4” long and the regular size is 6.5” long, not including the bristles </li>
                </ul>
                <ul>
                    <strong> Are our products cruelty free?</strong>
                    <li>Yes, 100% cruelty free and the bristles are synthetic BUT super soft (you won’t regret it!)</li>
                </ul>
                <ul>
                    <strong>How to clean the brushes and beauty sponges?</strong>
                    <ol>
                        <li>Wet the bristles with lukewarm water and use desire cleanser</li>
                        <li>Gently massage the tips of the bristles in your palms/hands</li>
                        <li>Rinse bristles and repeat till clean</li>
                        <li>Squeeze out excess moisture with a clean towel</li>
                        <li>Reshape brush head and lay flat to air dry or pointed downwards so no water residue get into the ferrule</li>
                        <li>For the beauty sponge, wet and use desire cleanser and massage till clean and let air dry</li>
                    </ol>
                </ul>
                <p> Note: if you use the brushes daily, please remember to clean them weekly.  Clean brushes allow makeup to apply more smoothly, as well as helping your skin from building up from oils/dead skin/debris that can cause clogged pores.</p>
                <p><img src="https://s3.ca-central-1.amazonaws.com/img.kavaglam.com/faq_cleaning_01.jpg" alt="How to clean your brushes" /></p>
            </div>
        </div>
    )
}
