import './index.scss'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addPromo } from '../../actions/cartActions'
import Badge from '@material-ui/core/Badge';

export default function Summary() {
    const dispatch = useDispatch()
    const { items, addedItems, total, subtotal, shipping, discount /*, promo */ } = useSelector(state => ({
        addedItems: state.cart.addedItems,
        items: state.cart.items,
        total: state.cart.total,
        subtotal: state.cart.subtotal || 0,
        shipping: state.cart.shipping,
        discount: state.cart.discount,
        promo: state.cart.promo
    }))
    const [promoMessage, setPromoMessage] = useState('');
    const handlePromoSubmit = async (e) => {
        e.preventDefault()
        let promo_result = await dispatch(addPromo(e.target.promo_code.value.toUpperCase()))
        promo_result.data.error ? setPromoMessage('Invalid promo code!') : setPromoMessage(`Promo code ${e.target.promo_code.value.toUpperCase()} added`) 
    }
    const CustomBadge = (props) => {
        const { overlap = 'rectangular', ...rest } = props;
        return <Badge overlap={ overlap } { ...rest } />;
    };

    return(
        <div className="cart-info">
            <ul>
                { Object.entries(addedItems)?.map(([key, val], i) =>
                    <li key={i}>
                        <CustomBadge badgeContent={val} color="secondary" > 
                            <img src={items.find(i=>i.productId === key).images[0]} alt={key} />
                        </CustomBadge>
                        <span>{key}</span>
                        <span>${items.find(i=>i.productId === key).price}</span> 
                    </li> 
                )}
            </ul>
            <hr />
            <form onSubmit={handlePromoSubmit} className="form-submit">
                <input type="text" name='promo_code' placeholder="Discount code" />
                <input type='submit' value='Apply' className='btn btn-secondary btn-sm' />
                {promoMessage && (<p>{promoMessage}</p>) }
            </form>
            <hr />
            <div className="cart-info-subtotal">
                <span>Subtotal</span>
                <span>${total}</span>
                <span>Discount</span>
                <span>- ${discount ? discount.toFixed(2) : 0}</span>
                <span>Shipping</span>
                <span>{shipping >= 0 ? '+ $' + shipping.toFixed(2) : "Calculated at checkout"}</span>
            </div>
            <hr />
            <div className="cart-info-total">
                <span>Total</span>
                <span>CAD</span>
                <span>${ subtotal.toFixed(2) }</span>
            </div>
        </div>
    )
}