import './index.scss'

export default function About() {
    
    return (
        <div className='wrapper'>
            <div className='title'> about us </div>
            <div className='about'>
                
                <h3>OUR STORY</h3>
                <p>Kavaglam started off as passion project because I wanted something more than just your basic black brush handles. My idea of these brushes was to make them glamorous, fun, and sparkly. Before starting this side business, I worked full-time in the animal health care field. After having my two daughters, and current pandemic, my passion project became my new business as I became a stay-at-home mom. This is also where the name “kava-glam” came from, inspiration of my two daughters (Kara & Eva).
                </p>

                <p>My goal was to create brushes that were versatile for your everyday use OR on the go. Simply throw them into your travel bag, makeup bag, or purse because they are the perfect size. 
                </p>

                <p>Our brushes and beauty sponges are 100% cruelty free with synthetic bristles but are still super soft and high quality. 
                Thank you to everyone for their ongoing support, it is greatly appreciated!
                I am excited to see where this journey takes me.</p>

                <p>xo, Kristi </p> 

            </div>
        </div>
    )
}
