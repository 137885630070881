import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { removeItem, addQuantity, subtractQuantity, addMsg } from '../../actions/cartActions'

export default function Cart({ history }){
    const { total, items, addedItems } = useSelector(state => ({
        addedItems: state.cart.addedItems,
        items: state.cart.items,
        total: state.cart.total
    }))
    const dispatch = useDispatch()
    const handleCheckout = e => {
        e.preventDefault()
        dispatch(addMsg(e.target['cart-msg'].value))
        history.push("/info")
    }

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>Your cart</span>
            </div>
            <div className="cart">
                <div className="cart-legend">
                    <span>QUANTITY</span>
                    <span>TOTAL</span>
                </div>
                <hr />
                <ul className="cart-items">
                    {addedItems ? Object.entries(addedItems).map(([key, value], idx)=>
                        <li key={idx}>
                            <img src={items.find(i => i.productId === key).images[0]} alt={items.find(i => i.productId === key).productname} />
                            <span>{items.find(i => i.productId === key).productname}</span>
                            <div>
                                <span>{value}</span>
                                <i onClick={()=>dispatch(addQuantity(key))}>
                                    <svg height="24px" viewBox="0 0 24 24" width="24px" fill="#333">
                                        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/>
                                    </svg>
                                </i>
                                <i onClick={()=>dispatch(subtractQuantity(key))}>
                                    <svg height="24px" viewBox="0 0 24 24" width="24px" fill="#333">
                                        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                                    </svg>
                                </i>
                                <button onClick={()=>dispatch(removeItem(key))}>Remove</button>
                            </div>
                            <span>${items.find(i => i.productId === key).price}</span> 
                        </li> 
                        
                    ) : <li>Cart is empty.</li>}
                </ul>
                <hr />
                <form onSubmit={handleCheckout} >
                    <div className="cart-footer">
                        <div>
                            <span>Special instructions for seller:</span>
                            <textarea  id="cart-msg" name="cart-msg" rows="5"></textarea>
                            <p>*Please note if you would like an additional information about your order.</p>
                        </div>
                        <div>
                            <span>SUBTOTAL</span>
                            <span>${total}</span>
                            <span>ALL SALES FINAL.</span>
                            <span>taxes and shipping calculated at checkout.</span>
                            <button type='submit'>Checkout</button>
                        </div>
                    </div>
                </form>
            </div> 
              
        </div>
    )
}
