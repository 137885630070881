import {Route} from 'react-router-dom'

export default function AuthRoute({isAuth, component: Component, ...restProps}) {

    return(
        <Route {...restProps}
            render={
                (routeProps)=>{
                    if (isAuth){
                        return <Component {...routeProps}/>
                    } else {
                        return routeProps.history.goBack()
                    }
                }
            }
        />
    )
} 