import './index.scss'

export default function Admin({ history }) {
    
    return (
        <div className='wrapper'>
            <div className='title'>
                <span>Admin Control Panel</span>
            </div>
            <div>
                <button onClick={()=> history.push('/admin/listallpromos')}>List All Promos</button>
            </div>
            <br/>
            <div>
                <button onClick={()=> history.push('/admin/listallnewsletter')}>List All Newsletter</button>
            </div>
            <br/>
            <div>
                <button onClick={()=> history.push('/admin/listallorders')}>List All Orders</button>
            </div>
            <br/>
        </div>
    )
}