import './index.scss'
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
    const [index, setIndex] = useState(0)
    const handleSelect = (selectedIndex, e) => setIndex(selectedIndex)

    return (
        <>
        <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_01.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Every Day Essentials Collection</h3>
                    <button><Link to='/products'>SHOP NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_02.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Winter Collection</h3>
                    <button><Link to='/products'>DISCOVER NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_03.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Autumn Collection</h3>
                    <button><Link to='/products'>SHOP NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_04.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Summer Collection</h3>
                    <button><Link to='/products'>DISCOVER NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_05.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Outdoor Collection</h3>
                    <button><Link to='/products'>SHOP NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_06.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Travel Collection</h3>
                    <button><Link to='/products'>DISCOVER NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} >
                <div className="carousel-item active" style={{backgroundImage: `url('${process.env.PUBLIC_URL + '/images/hero_07.jpg'}')`}} />
                <Carousel.Caption>
                    <h3>Travel Collection</h3>
                    <button><Link to='/products'>DISCOVER NOW</Link></button>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        <div className='wrapper'>
            <h3 className="home-h3">GLAMOROUS BEAUTY BRUSHES FOR YOUR EVERYDAY</h3>
            <div className='collection-items'>
                    <img src={process.env.PUBLIC_URL + '/images/brush_01.jpg'} alt='' />
                    <p>Mini size but still comfortable enough to use as your everday</p>
                    <img src={process.env.PUBLIC_URL + '/images/brush_02.jpg'} alt='' />
                    <p>Super soft bristles making application more enjoyable</p>
                    <img src={process.env.PUBLIC_URL + '/images/brush_03.jpg'} alt='' />
                    <p>Perfect for travelling, perfect for you</p>
            </div>
            <div><button><Link to='/products'>VIEW ALL PRODUCTS</Link></button></div>
            
        </div>
        </>
    )
}
