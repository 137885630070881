import './index.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addShipping } from '../../actions/cartActions'
import Summary from '../Summary'

export default function Shipping({ history }) {

	const dispatch = useDispatch()
	const { profile, rates } = useSelector(state => ({
		profile: state.currentUser.profile,
		rates: state.shipping
    }))
	const [required, setRequired] = useState(false)
	const handleChecked = (rate) => { setRequired(true); dispatch(addShipping(rate)) }
	const handleFormSubmit = e => {
        e.preventDefault()
        required && history.push("/payment")
    }
	
    return (
        <div className='wrapper'>
			 <div className='title'>
                <span>Shipping</span>
            </div>
			<div className="shipping">
				<div className="shipping-panel">
					<span>
						cart 
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg> 
						information
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg>
						<strong>shipping</strong>
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg>
						payment
					</span>
					<div className="shipping-info">
                        <span>Contact</span>
                        <span>{profile ? profile.email : ''}</span>
                        <Link to='/info'>Change</Link>
                        <hr/>
                        <span>Ship to</span>
                        <span>{profile ? profile.addressString : ''}</span>
                        <Link to='/info'>Change</Link>
                    </div>
                    <div className="shipping-method shipping-items">
                        <span>Shipping Method</span>
                        <ul className="shipping-items">
							{Object.keys(rates).length && rates.rates.length && rates.rates.map((rate, key) => (
								<li key={key}>
									<input type="radio" id={rate.serviceCode} name='rate' value={rate.priceDetails.due} onChange={() => handleChecked(rate)} required />
									<span>{rate.serviceName}</span>
									<span>{rate.priceDetails.due ? `$${rate.priceDetails.due}` : 'FREE'}</span>
								</li>
							))}
                        </ul>
                    </div>
					<form onSubmit={handleFormSubmit} className="form-submit">
						<input type='submit' className='btn btn-success' value='Continue to payment' />
						<Link to='/info'>Return to information</Link>
					</form>
				</div>
				<Summary />
			</div>
		</div>
    )
}