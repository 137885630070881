import { useState, useEffect } from 'react'
import { listNewsletter } from '../../../actions/newsletterActions.js'

export default function ListAllNewsletter({ history }) {

    const [newsletterList, setNewsletterList] = useState([])
    const fetchNewsletter = async () => {
        let newsletter = await listNewsletter()
        if (newsletter && newsletter.length) setNewsletterList(newsletter)
    }

    useEffect(()=>{
        fetchNewsletter()
    },[])

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>List All Newsletter</span>
            </div>
            <h5>List All Newsletter</h5>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Subscription</th>
                    </tr>
                </thead>
                <tbody>
                {newsletterList.length ? newsletterList.map((item, i)=>
                    <tr key={i}>
                        <td>{item.email}</td>
                        <td>{item.subscribed ? 'Subscribed' : 'Unsubscribed'}</td>
                    </tr>
                ) : null}          
                </tbody>
            </table>
            <br></br>
            <button onClick={()=> history.push('/admin')}>Back</button>
        </div>
    )
}