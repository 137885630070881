import { CURRENT_USER, CREATE_SESSION, DESTROY_SESSION, CREATE_USER, CREATE_PROFILE } from '../actions/userActions'

const initState = { email: '', profile: {}}
export const currentUser = (state = initState, action) => {
    switch (action.type){
        case CURRENT_USER: return { ...state, ...action.data }
        case CREATE_SESSION: return { ...state, ...action.data }
        case DESTROY_SESSION: return initState
        case CREATE_USER: return { ...state, ...action.data }
        case CREATE_PROFILE: return { ...state, profile: action.data }
        default: return state
    }
}