import './index.scss'
import GoogleAutoComplete from 'react-auto-complete-address-fields';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { createProfile } from '../../actions/userActions'
import { getRates } from '../../actions/shippingActions'
import Summary from '../Summary'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Paypal from '../../utils/Paypal'
import Gpay from '../../utils/Gpay'
import Applepay from '../../utils/Applepay'

export default function Info({ history }) {
	const dispatch = useDispatch()
	const { total } = useSelector(state => ({
		total: state.cart.total
    }))
	const [value, setValue] = useState()
	const [address, setAddress] = useState({ 
		subpremise:'', 
		street_number:'', 
		route:'', 
		locality:'', 
		administrative_area_level_1:'', 
		postal_code:'', 
		country:'', 
		countryShort:'' 
	})
	const handleInput = ({ name, value }) => setAddress(prev => ({ ...prev, [name]: value }))
	const callbackFunc = autoCompleteData => {
		autoCompleteData.address_components.forEach(item =>  handleInput({ name: [item.types[0]], value: item.long_name }) )
		handleInput({ name: 'countryShort', value: autoCompleteData.address_components.find((e)=>e.types[0] === "country" ).short_name})
	}
	const handleFormSubmit = e => {
    e.preventDefault();
		const addressString = `${address.subpremise ? address.subpremise + ' - ' : ''}${address.street_number} ${address.route}, ${address.locality}, ${address.administrative_area_level_1}, ${address.postal_code}`
		const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => first.toLocaleUpperCase(locale) + rest.join('')

		dispatch(createProfile({
			email: e.target['email']?.value, 
			firstname: capitalizeFirstLetter(e.target['firstname']?.value), 
			lastname: capitalizeFirstLetter(e.target['lastname']?.value), 
			address: address, 
			addressString: addressString, 
			phone: e.target['phone']?.value 
		}))
		dispatch(getRates({ address, total }))
        history.push("/shipping")
    }
	
    return (
        <div className='wrapper'>
			 <div className='title'>
                <span>Information</span>
            </div>
			<div className="information">
				<div className="info-panel">
					<span> 
						cart 
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg> 
						<strong>information</strong>
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg>
						shipping
						<svg height="24px" viewBox="0 0 24 24" width="20px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
						</svg>
						payment
					</span>
					<div className="express-checkout">
						<div>
							Express checkout
						</div>
						<button disabled><Applepay /></button>
						<button disabled><Paypal /></button>
						<button disabled><Gpay /></button>
					</div>
					<div className="or">
						<span>or</span>
					</div>
					<div className="contact-info-header">
						<span>Contact Information</span>
						<span>Already have an account? <Link to='/sign_in'>Log in</Link></span>
					</div>
					<form onSubmit={handleFormSubmit}>
						<input type='text' name='email' placeholder='Email' required />
						<div className="shipping-header">
							<span>Shipping address</span>
						</div>
						<div className="shipping-address">
							<div className='address_name'>
								<input type="text" name="firstname" placeholder="First Name*" required />
								<input type="text" name="lastname" placeholder="Last Name*" required />
							</div>
							<GoogleAutoComplete apiKey={ process.env.REACT_APP_GOOGLE_KEY } id='location' placeholder='Address' callbackFunction={callbackFunc}
								fields={{
									subpremise: "subpremise",
									streetAddress: "route",
									locality: "locality",
									cityOrState: "administrative_area_level_1",
									postalcode: "postal_code",
									country: "country"
								}}
							/>
							<input type='text' name={'subpremise'} value={address['subpremise']} placeholder='Apartment, suite, etc. (optional)' onChange={e=>handleInput(e.currentTarget)} />
							<input type='text' name={'administrative_area_level_1'} value={address['administrative_area_level_1']} placeholder='City' onChange={e=>handleInput(e.currentTarget)} />
							<div className='address_area'>
								<input type='text' name={'country'} value={address['country']} placeholder='Country/region' onChange={e=>handleInput(e.currentTarget)} />
								<input type='text' name={'locality'} value={address['locality']} placeholder='Province' onChange={e=>handleInput(e.currentTarget)} />
								<input type='text' name={'postal_code'} value={address['postal_code']} placeholder='Postal Code' onChange={e=>handleInput(e.currentTarget)} />
							</div>
							<PhoneInput country={'ca'} inputProps={{ name: 'phone', required: true }} value={value} onChange={setValue} />
							<div className="form-submit">
								<input type='submit' className='btn btn-success' value='Continue to shipping' />
								<Link to='/cart'>Return to cart</Link>
							</div>
						</div>
					</form>
				</div>
				<Summary />
			</div>
		</div>
    )
}