import { store } from '../index.js'
const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const INITIAL_STATE = 'INITIAL_STATE';
export const CART = 'CART';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const SUB_SHIPPING = 'SUB_SHIPPING';
export const ADD_MSG = 'ADD_MSG'
export const ADD_PROMO = 'ADD_PROMO';
export const CREATE_ORDER = 'CREATE_ORDER';
export const RESET_STATE = 'RESET_STATE';

export const currentCart = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/cart/cart`, {
            credentials: 'include', 
            method: 'GET'
        }).then(res => res.json())
        .then(data => !data.error && dispatch({ type: CART, data }) )
    }
}
export const addItem = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/cart/cart`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify({ ...store.getState().cart.addedItems, [params]: store.getState().cart.addedItems[params] + 1 || 1 })
        }).then(res => res.json())
        .then(data => dispatch({ type: CART, data }))
    }
}
export const removeItem = (params) => {
    let { [params]: _, ...rest } = store.getState().cart.addedItems;
    return function(dispatch){
        return fetch(`${ baseUrl }/cart/cart`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(rest)
        }).then(res => res.json())
        .then(data => dispatch({ type: CART, data }))
    }
}
export const subtractQuantity = (params) => {
    let { [params]: _, ...rest } = store.getState().cart.addedItems;
    let remainder = _ <= 1 ? rest : { ...store.getState().cart.addedItems, [params]: store.getState().cart.addedItems[params] - 1 };
    return function(dispatch){
        return fetch(`${ baseUrl }/cart/cart`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(remainder)
        }).then(res => res.json())
        .then(data => dispatch({ type: CART, data }))
    }
}
export const addQuantity = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/cart/cart`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify({ ...store.getState().cart.addedItems, [params]: store.getState().cart.addedItems[params] + 1 })
        }).then(res => res.json())
        .then(data => dispatch({ type: CART, data }))
    }
}
export const addShipping = (data) => {
    return function(dispatch){
        dispatch({ type: ADD_SHIPPING, data })
    }
}
export const subShipping = (id) => {
    return { type: SUB_SHIPPING, id }
}
export const addMsg = (data) => {
    return function(dispatch){
        dispatch({ type: ADD_MSG, data })
    }
}
export const addPromo = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/promo/validate`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify({ promo: params })
        }).then(res => res.json())
        .then(data => dispatch({ type: ADD_PROMO, data }))
    }
}
export const sitewide = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/promo/sitewide`, {
            credentials: 'include', 
            method: 'GET'
        }).then(res => res.json())
        .then(data => dispatch({ type: ADD_PROMO, data }))
    }
}
export const addOrder = () => {
    return function(dispatch){
        return fetch(`${ baseUrl }/order/create`, {
            headers: {'Content-Type':'application/json'},
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify({ cart: store.getState().cart, profile: store.getState().currentUser.profile })
        }).then(res => res.json())
        .then(data => dispatch({ type: CREATE_ORDER, data }))
    }
}
export const resetState = () => {
    return function(dispatch) {
        return dispatch({ type: RESET_STATE })
    }
}

