export const initialState = { cart: { addedItems:[], total: 0, promo: {}, items: 
        [
            {
                "images": [
                    "/images/products/confetti_brush_set_01.jpg",
                    "/images/products/confetti_brush_set_02.jpg",
                    "/images/products/confetti_brush_set_03.jpg",
                    "/images/products/confetti_brush_set_04.jpg",
                    "/images/products/confetti_brush_set_05.jpg",
                    "/images/products/confetti_brush_set_06.jpg",
                    "/images/products/confetti_brush_set_07.jpg"
                ],
                "stock": 100,
                "sale_price": 68,
                "description": `These beauties are in the perfect pastel colours with the softest and fluffiest bristles. They are absolutely so lush! These have a way to brighten up your vanity.`,
                "set": ['100% synthetic hairs & cruelty free', '(6 piece set includes)', 'Powder Brush', 'Curved Contour Brush', 'Full Foundation Brush', 'Strippling Brush', 'Highlight Brush', 'Crease Brush'],
                "price": 68,
                "productId": "confetti-brush-set",
                "productname": "Confetti Brush Set"
            },
            {
                "images": [
                    "/images/products/confetti_eye_brush_set_01.jpg",
                    "/images/products/confetti_eye_brush_set_02.jpg",
                    "/images/products/confetti_eye_brush_set_03.jpg",
                    "/images/products/confetti_eye_brush_set_04.jpg"
                ],
                "stock": 100,
                "sale_price": 38,
                "description": `Did you say confetti? Here we have our beautiful pastel eye brush set. This eye brush set goes hand in hand with the face set. A nice touch to brighten up your vanity.`,
                "set": ['100% synthetic hairs & cruelty free', '(4 piece set includes)', 'Eyeshadow Brush', 'Concealer Brush', 'Tapered Brush', 'Blending Brush'],
                "price": 38,
                "productId": "confetti-eye-brush-set",
                "productname": "Confetti Eye Brush Set"
            },
            {
                "images": [
                    "/images/products/unicorn_magic_brush_set_01.jpg",
                    "/images/products/unicorn_magic_brush_set_02.jpg",
                    "/images/products/unicorn_magic_brush_set_03.jpg",
                    "/images/products/unicorn_magic_brush_set_04.jpg"
                ],
                "stock": 100,
                "sale_price": 120,
                "description": `Here we have our beauty glamorous unicorn set. Featuring unicorn sparkly handles with white & pink Bristles. There nothing more magical than these beauties! These brushes are one of our only full size sets.`,
                "set": ['100% synthetic hairs & cruelty free', '(10 piece set includes)', 'Buffer Brush', 'Expert Brush', 'Powder Brush', 'Precise Powder Brush', 'Foundation Brush', 'Highlight Brush', 'Crease Brush', 'Angled Eyeshadow Brush', 'Eyeshadow Brush', 'Fan Brush'],
                "price": 120,
                "productId": "unicorn-magic-brush-set",
                "productname": "Unicorn Magic Brush Set"
            },
            {
                "images": [
                    "/images/products/mini_glam_brush_set_01.jpg",
                    "/images/products/mini_glam_brush_set_02.jpg",
                    "/images/products/mini_glam_brush_set_03.jpg",
                    "/images/products/mini_glam_brush_set_04.jpg",
                    "/images/products/mini_glam_brush_set_05.jpg",
                    "/images/products/mini_glam_brush_set_06.jpg"
                ],
                "stock": 99,
                "sale_price": 82,
                "description": `Pink is the new Black. Gorgeous pink handles with our twist of glam and of course with White and pink bristles. They are seriously so soft and fluffy! This set has everything you need for your everyday look to your night out look.`,
                "set": ['100% synthetic & cruelty free', '(8 piece set includes)', 'Tapered Brush', 'Powder Brush', 'Angled Brush', 'Flat Brush', 'Highlight Brush', 'Eyeshadow Brush', 'Small Eyeshadow Brush', 'Crease Brush'],
                "price": 82,
                "productId": "mini-glam-brush-set",
                "productname": "Mini Glam Brush Set"
            },
            {
                "images": [
                    "/images/products/snow_bunny_brush_set_01.jpg",
                    "/images/products/snow_bunny_brush_set_02.jpg",
                    "/images/products/snow_bunny_brush_set_03.jpg",
                    "/images/products/snow_bunny_brush_set_04.jpg",
                    "/images/products/snow_bunny_brush_set_05.jpg",
                    "/images/products/snow_bunny_brush_set_06.jpg"
                ],
                "stock": 99,
                "sale_price": 58.5,
                "description": `Here we have our snow bunny brush set. Sleek white handles with gold but of course with a touch of pink on the bristles. Minimal makeup use? These have exactly what you need for your everyday look.`,
                "set": ['100% synthetic hairs & cruelty free', '(5 piece set includes)', 'Expert Brush', 'Highlight Brush', 'Fan Brush', 'Small Contour Brush', 'Powder Brush'],
                "price": 58.5,
                "productId": "snow-bunny-brush-set",
                "productname": "Snow Bunny Brush Set"
            },
            {
                "images": [
                    "/images/products/pink_sparkle_beauty_sponge_01.jpg",
                    "/images/products/pink_sparkle_beauty_sponge_02.jpg",
                    "/images/products/pink_sparkle_beauty_sponge_03.jpg",
                    "/images/products/pink_sparkle_beauty_sponge_04.jpg"
                ],
                "stock": 100,
                "sale_price": 15,
                "description": `The Beauty Sponge is the perfect way to get a flawless, professional-looking finish in seconds. Use it to blend out foundation for an almost airbrushed finish. How to use it? When wet, it becomes mega soft and mimics pores to create a natural airbrushed application easily. Plus, it absorbs water, not makeup, so your fave formulas land on your skin, not in your sponge. It is cruelty-free and formulated without latex, parabens, sulfates, and phthalates.`,
                "price": 15,
                "productId": "pink-sparkle-beauty-sponge",
                "productname": "Pink Sparkle Beauty Sponge"
            },
            {
                "images": [
                    "/images/products/pink_rain_drop_beauty_sponge_01.jpg",
                    "/images/products/pink_rain_drop_beauty_sponge_02.jpg",
                    "/images/products/pink_rain_drop_beauty_sponge_03.jpg",
                    "/images/products/pink_rain_drop_beauty_sponge_04.jpg"
                ],
                "stock": 100,
                "sale_price": 12,
                "description": `The Beauty Sponge is the perfect way to get a flawless, professional-looking finish in seconds. Use it to blend out foundation for an almost airbrushed finish. How to use it? When wet, it becomes mega soft and mimics pores to create a natural airbrushed application easily. Plus, it absorbs water, not makeup, so your fave formulas land on your skin, not in your sponge. It is cruelty-free and formulated without latex, parabens, sulfates, and phthalates.`,
                "price": 12,
                "productId": "pink-rain-drop-beauty-sponge",
                "productname": "Pink Rain Drop Beauty Sponge"
            }
        ]
    }
}