import './index.scss'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { destroySession } from '../../actions/userActions'
import { useSelector, useDispatch } from 'react-redux'
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Navbar({ handleModal }) {
    const dispatch = useDispatch()
    const { addedItems, user } = useSelector(state => ({
        addedItems: state.cart.addedItems,
        user: state.currentUser
    }))
    const [ showMenu, setShowMenu ] = useState(false)
    const handleMenu = (e) => { e.preventDefault(); setShowMenu(!showMenu) }
    const CustomBadge = (props) => {
        const { overlap = 'rectangular', ...rest } = props;
        return <Badge overlap={ overlap } { ...rest } />;
    };

    return(
        <>
        <div className='topHeader'>
            <span>Free Shipping on orders $50+ within Canada & USA</span>
            <ul>
                {user && user.email && (<li>{user.email.split('@')[0]}</li>)}
                <li><NavLink to='/sign_in'>sign in</NavLink></li>
                <li><NavLink to='/sign_up'>register</NavLink></li>
                {user && user.email && (<li><a href='void(0)' onClick={(e)=>{ e.preventDefault(); dispatch(destroySession()) }}>signout</a></li>)}
                <li><a href='void(0)' onClick={ handleModal }>subscribe</a></li>
                <li><a href='https://www.facebook.com/kavaglam/' ><FontAwesomeIcon icon={faFacebook}/></a></li>
                <li><a href='https://www.instagram.com/kavaglam_/' ><FontAwesomeIcon icon={faInstagram}/></a></li>
            </ul>
        </div>
        <nav className='topNavbar'>
            <div className='nav-main-menu'>
                <div className="hamburger" onClick={handleMenu}>
                    <span /><span /> <span />
                </div>
                <NavLink to='/'>
                    <img className='logo' src={process.env.PUBLIC_URL + '/images/kavaglam_logo_long.svg'} alt="Kavaglam Logo" />
                </NavLink>
                <ul>
                    <li>{user.role === 'admin' ? (<NavLink to='/admin'>ADMIN</NavLink>) : (<NavLink to='/'>HOME</NavLink>) }</li>
                    <li><NavLink to='/products'>BRUSHES</NavLink></li>
                    <li><NavLink to='/reviews'>REVIEWS</NavLink></li>
                    <li><NavLink to='/faq'>FAQ</NavLink></li>
                    <li><NavLink to='/about'>ABOUT</NavLink></li>
                    <li>
                        <NavLink to='/cart'>
                            <IconButton aria-label="show cart" >
                                <CustomBadge badgeContent={ addedItems ? Object.values(addedItems).reduce((acc, i) => acc + i, 0) : 0 } color="secondary" overlap="rectangular">
                                    <ShoppingCartOutlinedIcon />
                                </CustomBadge>
                            </IconButton>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className={showMenu ? 'overlay' : 'hide'} onClick={handleMenu} />
            <div className={showMenu ? 'nav-side-menu' : 'hide'}>
                <a href='void(0)' onClick={handleMenu}> <i className='icon-close'>X</i> </a>
                <div className='nav-side-menu-content'>
                    <img className='logo' src={process.env.PUBLIC_URL + '/images/kavaglam_logo_long.svg'} alt="Kavaglam Logo" />
                    <ul>
                        <li onClick={handleMenu}>{user.admin ? (<NavLink to='/admin'>ADMIN</NavLink>) : (<NavLink to='/'>HOME</NavLink>) }</li>
                        <li onClick={handleMenu}><NavLink to='/products'>BRUSHES</NavLink></li>
                        <li onClick={handleMenu}><NavLink to='/reviews'>REVIEWS</NavLink></li>
                        <li onClick={handleMenu}><NavLink to='/faq'>FAQ</NavLink></li>
                        <li onClick={handleMenu}><NavLink to='/about'>ABOUT</NavLink></li>
                        <li onClick={handleMenu}>{ user ? user.email : '' }</li>
                        <li onClick={handleMenu}><NavLink to='/sign_in'>sign in</NavLink></li>
                        <li onClick={handleMenu}><NavLink to='/sign_up'>register</NavLink></li>
                        <li onClick={handleMenu}><a href='void(0)' onClick={(e)=>{ e.preventDefault(); dispatch(destroySession()) }}>signout</a></li>
                        <li onClick={handleMenu}><a href='void(0)' onClick={handleModal}>subscribe</a></li>
                        <li onClick={handleMenu}><a href='https://www.facebook.com/kavaglam/' ><FontAwesomeIcon icon={faFacebook}/></a></li>
                        <li onClick={handleMenu}><a href='https://www.instagram.com/kavaglam_/' ><FontAwesomeIcon icon={faInstagram}/></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        
        </>
    )
}


