const baseUrl = process.env.NODE_ENV !== 'development' 
    ? process.env.REACT_APP_BASE_URL_LIVE 
    : process.env.REACT_APP_BASE_URL_LOCAL;
export const ORDER = 'ORDER';

export const orders = () => {
    return fetch(`${ baseUrl }/order/orders`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST'
    }).then(res => res.json())
}
export const tracking = (params) => {
    return fetch(`${ baseUrl }/order/tracking`, {
        headers: { 'Content-Type':'application/json' },
        credentials: 'include', 
        method: 'POST',
        body: JSON.stringify(params)
    }).then(res => res.json())
}
export const myOrder = (params) => {
    return function(dispatch){
        return fetch(`${ baseUrl }/order/my_order`, {
            headers: { 'Content-Type':'application/json' },
            credentials: 'include', 
            method: 'POST',
            body: JSON.stringify(params)
        }).then(res => res.json())
        .then(data => dispatch({ type: ORDER, data }) )
    }
}