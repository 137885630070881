import './index.scss'

export default function Returns() {

    return (
        <div className='wrapper'>
            <div className='title'>
                <span>returns / refunds</span>
            </div>
            <div className='returns'>
                <ul>
                    <strong>RETURNS/REFUNDS</strong>
                    <p>
                        We are sorry things didn’t work out for you BUT for any reason you are unsatisfied with your order. Please send us an email at kavaglambeauty@gmail.com with your order number so we can further assist you. 
                    </p>
                    <li>We will accept refunds/returns within 7 days from the date of package delivered.</li>
                    <li>Original shipping and return shipping charges are the responsibility of the customer and non-refundable. </li>
                    <li>Items must be received in its original condition in order for a full refund. </li>
                    <p> *All sale items are non-refundable</p>
                    
                </ul>
                <ul>
                    <strong>DAMAGED ITEM</strong>
                    <p>In the unlikely event that you received a defective item, please send us an email at kavaglambeauty@gmail.com with a brief description and picture within 2 days of receiving it so we can assist you ASAP. Each case will be assessed individually and we will do our best to accommodate.</p>
                    <p>
                    Please note that we are not responsible for damages resulting from shipping. 
                    Thank you for understanding.
                    </p>
                </ul>
            </div>
        </div>
    )
}