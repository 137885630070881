import './index.scss'
import { Link } from 'react-router-dom';
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer({handleModal}) {

    return (
        <>
        <section className='footer'>
            <ul>
                <li><Link to='/about'>ABOUT US</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
                <li><Link to='/returns'>RETURN/REFUND POLICY</Link></li>
                <li><Link to='/delivery'>SHIPPING & DELIVERY</Link></li>
                <li><Link to='/contact'>CONTACT US</Link></li>
            </ul>
            <ul className='subscribe'>
                {/* <h3>REWARDS</h3> */}
                <h5>REWARDS</h5>
                <p>Sign up for the latest news, offers and styles and receive 10% off your first order.</p>
                <a href='void(0)' onClick={ handleModal }>subscribe</a>
            </ul>
            <ul className='socials'>
                {/* <h3>FOLLOW US</h3> */}
                <li><a href='https://www.facebook.com/kavaglam'><FontAwesomeIcon icon={faFacebook}/> &nbsp; Facebook</a></li>
                <li><a href='https://www.instagram.com/kavaglam_/'><FontAwesomeIcon icon={faInstagram}/> &nbsp; Instagram</a></li>
            </ul>
        </section>
        <div className='copyright'>
                <span>© copyright 2021, Kavaglam Canada</span>
        </div>
        </>
    )
}
