import './index.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Products() {
    const items = useSelector(state => state.cart.items)

    return (
        <div className="wrapper">
            <div className="title">
                <span>Beauty Brushes</span>
            </div>
            <div className="products">
                {items.map((item, i) =>
                <div className="card" key={ i }>
                    <div className="card-image">
                        <Link to={ `/products/` + item.productId }>
                            <img src={ item.images[0] } alt={ item.productname }/>
                        </Link>
                        <span className="card-title">{ item.productname } ~ ${ item.price }</span>
                    </div>
                </div> 
                )}
            </div>
        </div>
    )
}