import { GET_RATES } from '../actions/shippingActions'

const initState = { rates: {} }
export const shipping = (state=initState, action)=>{
    switch (action.type){
        case GET_RATES:
            let results = action.data.sort((a, b) => a.priceDetails.due - b.priceDetails.due).filter(e => e.serviceCode !== 'DOM.EP' )
            try { if (action.total >= 50) results.find(r => r.serviceCode === 'DOM.RP').priceDetails.due = 0 } catch(err) {}
            try { if (action.total >= 50) results.find(r => r.serviceCode === 'USA.SP.AIR').priceDetails.due = 0 } catch(err) {}
            if (results) return { ...state, rates: results }
            return { ...state }
            
        default: 
            return state
    }
}